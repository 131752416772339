@import "../common.scss";

.slider {
  width: responsiveSize(1920);
  overflow: hidden;
  position: relative;

  &__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
  }

  &--fast {
    --_animation-duration: 20s;
  }
  &--slow {
    --_animation-duration: 40s;
  }
  &--right {
    --_animation-direction: reverse;
  }
  &--left {
    --_animation-direction: forwards;
  }

  &[data-animated="true"] .slider__inner {
    width: auto;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 775px) {
  .slider {
    width: responsiveSizeMobile(775);
  }
}
