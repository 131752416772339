@font-face {
  font-family: "Ayuthaya";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Ayuthaya.ttf");
}

@font-face {
  font-family: "Fredoka One";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FredokaOne-Regular.ttf");
}

@font-face {
  font-family: "Fredoka SemiCondensed";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/FredokaSemiCondensed/Fredoka_SemiCondensed-Light.ttf");
}

@font-face {
  font-family: "Fredoka SemiCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FredokaSemiCondensed/Fredoka_SemiCondensed-Regular.ttf");
}

@font-face {
  font-family: "Fredoka SemiCondensed";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/FredokaSemiCondensed/Fredoka_SemiCondensed-Medium.ttf");
}

@font-face {
  font-family: "Fredoka SemiCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/FredokaSemiCondensed/Fredoka_SemiCondensed-SemiBold.ttf");
}

@font-face {
  font-family: "Fredoka SemiCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/FredokaSemiCondensed/Fredoka_SemiCondensed-Bold.ttf");
}

@font-face {
  font-family: "Chaloops";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Chaloops/Chaloops-Regular.ttf");
}

@font-face {
  font-family: "Chaloops";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Chaloops/Chaloops-Medium.ttf");
}

@font-face {
  font-family: "Chaloops";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Chaloops/Chaloops-Bold.ttf");
}

@font-face {
  font-family: "minion-pro";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/MinionPro/MinionPro-Regular.ttf");
}

@font-face {
  font-family: "minion-pro";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/MinionPro/MinionPro-Medium.ttf");
}

@font-face {
  font-family: "minion-pro";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/MinionPro/MinionPro-Semibold.ttf");
}

@font-face {
  font-family: "minion-pro";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/MinionPro/MinionPro-Bold.ttf");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/Avenir/Avenir-Light-07.ttf");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Avenir/Avenir-Book-01.ttf");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Avenir/Avenir-Medium-09.ttf");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Avenir/Avenir-Heavy-05.ttf");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Avenir/Avenir-Black-03.ttf");
}

@font-face {
  font-family: "Baloo Bhaijaan 2";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/BalooBhaijaan2-Regular.ttf");
}

@font-face {
  font-family: "Baloo Bhaijaan 2";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/BalooBhaijaan2-Bold.ttf");
}
