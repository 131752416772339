@import "../../common.scss";

.learn-more {
  background-color: white;
  position: relative;

  &__container {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: responsiveSize(50);
    padding-bottom: responsiveSize(60);
    padding-top: responsiveSize(20);
    width: responsiveSize(1320);
    margin: auto;
  }

  &__title {
    color: black;
    font-family: "minion-pro";
    font-weight: 700;
    font-size: responsiveSize(50);
    margin: auto;
    text-align: center;
  }

  &__frame {
    display: flex;
    align-items: center;
    gap: responsiveSize(44);

    img {
      width: responsiveSize(748);
      height: responsiveSize(521);
      border-radius: responsiveSize(9);
      margin: 0;
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: responsiveSize(15);
      align-items: flex-start;
      text-align: left;
      font-family: "Baloo Bhaijaan 2";
      font-weight: 400;
      font-size: responsiveSize(28);
      width: responsiveSize(462);
      margin: 0;

      p {
        line-height: 1.1;
        margin: 0;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #51aabc;
    text-decoration: none;
    font-family: "Avenir";
    font-weight: 900;
    text-transform: uppercase;
    font-size: responsiveSize(37);
    border-radius: responsiveSize(4);
    font-size: responsiveSize(37);
    width: responsiveSize(394);
    height: responsiveSize(71);
  }

  @media only screen and (max-width: 775px) {
    &__container {
      width: 100%;
      padding-bottom: responsiveSizeMobile(60);
      padding-top: responsiveSizeMobile(20);
      gap: responsiveSizeMobile(30);
    }

    &__title {
      font-size: responsiveSizeMobile(61);
      width: responsiveSizeMobile(610);
      margin: auto;
    }

    &__frame {
      flex-direction: column;
      justify-content: inherit;
      align-items: inherit;

      img {
        width: responsiveSizeMobile(748);
        height: responsiveSizeMobile(521);
        border-radius: responsiveSizeMobile(9);
        margin: auto;
      }

      &--content {
        font-size: responsiveSizeMobile(30);
        width: responsiveSizeMobile(662);
        gap: responsiveSizeMobile(40);
        text-align: center;
        align-items: center;
        margin: 0 auto;
        justify-content: inherit;
      }
    }

    &__btn {
      width: responsiveSizeMobile(394);
      height: responsiveSizeMobile(71);
      border-radius: responsiveSizeMobile(4);
      font-size: responsiveSizeMobile(37);
    }
  }
}
