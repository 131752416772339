@import "../common.scss";

.testimony {
  text-align: center;
  width: responsiveSize(590);
  height: responsiveSize(347);
  border-radius: responsiveSize(9);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  font-family: 'Avenir';
  p {
    font-size: responsiveSize(32);
    line-height: responsiveSize(36);
    width: responsiveSize(540);
  }
  div {
    position: absolute;
    bottom: responsiveSize(21);
    color: #A8BFC1;
    font-size: responsiveSize(24);
  }

  @media only screen and (max-width: 775px) {
    width: responsiveSizeMobile(590);
    height: responsiveSizeMobile(347);
    border-radius: responsiveSizeMobile(9);
    p {
      font-size: responsiveSizeMobile(32);
      line-height: responsiveSizeMobile(36);
      width: responsiveSizeMobile(540);
    }
    div {
      bottom: responsiveSizeMobile(21);
      font-size: responsiveSizeMobile(24);
    }
  }
}