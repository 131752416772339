@import "../../common.scss";

* {
  line-height: 1;
}

.hero-section {
  background: white;

  &__container {
    z-index: 10;
    position: relative;
    flex-wrap: nowrap;
    margin: 0 auto;
    width: responsiveSize(1320);
    padding: responsiveSize(75) 0 responsiveSize(70) responsiveSize(75);
    gap: responsiveSize(110);
    height: fit-content;
    color: black;
    display: flex;
  }

  &__title {
    font-size: responsiveSize(45);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
  }

  &__image {
    &--awlad-swim {
      position: absolute;
      background: url("../../../public/images/awlad-swim-desktop.svg");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      left: 0;
    }

    &--iphone {
      width: responsiveSize(514);
      height: responsiveSize(1027);
    }

    &--logo {
      margin-top: responsiveSize(57);
      width: responsiveSize(425.4);
      height: responsiveSize(140);
      background-image: url("../../../public/images/logo-desktop.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__content {
    flex-direction: column;
    width: responsiveSize(552);

    &--desktop {
      display: flex;
      h1 {
        margin: responsiveSize(15) 0;
      }
    }

    &--mobile {
      display: none;
    }
  }

  &__info {
    position: relative;
    width: responsiveSize(552);

    &--container {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__free {
    color: white;
    position: relative;
    background-color: #4cb3c4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "Fredoka SemiCondensed";
    font-weight: 600;
    border-radius: responsiveSize(8);
    width: responsiveSize(552);
    height: responsiveSize(54);
    font-size: responsiveSize(40);
    margin: responsiveSize(15) 0;

    &--heart {
      position: absolute;
      width: responsiveSize(89);
      height: responsiveSize(89);
      right: responsiveSize(-89);
      top: responsiveSize(-14);
    }
  }

  &__desc {
    position: relative;
    text-align: left;
    font-size: responsiveSize(28);
    font-family: "Baloo Bhaijaan 2";
    margin: 0;
  }

  @media only screen and (max-width: 775px) {
    background: linear-gradient(
      to bottom,
      #c0ecf8 responsiveSizeMobile(696),
      #cfcce3
    );

    &__container {
      padding: responsiveSizeMobile(37) responsiveSizeMobile(46) 0
        responsiveSizeMobile(46);
      gap: responsiveSizeMobile(19);
      width: inherit;
      flex-wrap: wrap;
    }

    &__title {
      font-size: responsiveSizeMobile(40);
    }

    &__image {
      &--awlad-swim {
        background: url("../../../public/images/awlad-swim.svg");
        background-repeat: no-repeat;
        background-size: contain;
        top: responsiveSizeMobile(-65);
        left: 0;
        width: responsiveSizeMobile(775);
        height: responsiveSizeMobile(1018.85);
      }

      &--logo {
        width: responsiveSizeMobile(210);
        height: responsiveSizeMobile(210);
        margin: 0;
        background-image: url("../../../public/images/logo-mobile.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }

      &--iphone {
        width: responsiveSizeMobile(433);
        height: responsiveSizeMobile(866);
      }
    }

    &__content {
      width: responsiveSizeMobile(230);
      margin-top: responsiveSizeMobile(58);
      height: 100%;

      &--mobile {
        display: flex;
      }

      &--desktop {
        display: none;
      }

      img {
        margin: auto;
      }
    }

    &__info {
      width: 100%;
      &--container {
        display: flex;
        flex-direction: column;
      }
    }

    &__free {
      color: #51aabc;
      position: relative;
      background-color: white;
      border-radius: responsiveSizeMobile(8);
      width: responsiveSizeMobile(678);
      height: responsiveSizeMobile(68);
      font-size: responsiveSizeMobile(40);

      &--heart {
        top: responsiveSizeMobile(-14);
        right: inherit;
        width: responsiveSizeMobile(89);
        height: responsiveSizeMobile(89);
      }

      &:after {
        content: "";
        position: absolute;
        top: responsiveSizeMobile(-170);
        left: responsiveSizeMobile(-46);
        z-index: -1;
        width: responsiveSizeMobile(775);
        height: responsiveSizeMobile(170);
        background: linear-gradient(
          to bottom,
          #d0cae200 responsiveSizeMobile(150),
          #c1c4de
        );
      }
    }

    &__desc {
      text-align: center;
      font-size: responsiveSizeMobile(34);
      margin: responsiveSizeMobile(30) 0;
      &:after {
        content: "";
        position: absolute;
        top: responsiveSizeMobile(-110);
        left: responsiveSizeMobile(-46);
        z-index: -1;
        width: responsiveSizeMobile(775);
        height: responsiveSizeMobile(350);
        background: #c0c3de;
      }
    }
  }
}
