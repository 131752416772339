@import "../../common.scss";

.donation-section1 {
  height: responsiveSize(987);
  background: linear-gradient(to bottom, #51AABC responsiveSize(401), #AFCDE1);

  .donation-section1-content {
    margin: auto;
    width: responsiveSize(1246);
    display: flex;
    padding-top: responsiveSize(51);
    justify-content: center;

    .donation-section1-col1 {
      width: responsiveSize(578);

      .lf-player-container {
        width: responsiveSize(578);
        height: responsiveSize(578);
      }

      h1 {
        font-family: 'minion-pro';
        font-size: responsiveSize(50);
        line-height: responsiveSize(55);
        font-weight: bold;
        text-align: center;
      }
    }

    .donation-section1-col2 {
      width: responsiveSize(641);
      height: responsiveSize(870);
      border-radius: responsiveSize(25);
      margin-left: responsiveSize(75);
      color: black;
      background-color: white;
      font-family: 'Avenir';
      display: flex;
      flex-direction: column;
      padding: responsiveSize(35);
      box-sizing: border-box;

      h2 {
        text-align: center;
        font-size: responsiveSize(34);
        font-weight: 800;
        margin-top: 0;
        margin-bottom: responsiveSize(13.5);
      }

      p {
        font-size: responsiveSize(22);
        font-weight: 400;
        margin-top: 0;
        margin-bottom: responsiveSize(21.5);
        line-height: responsiveSize(30);
      }

      label {
        color: #51AABC;
        font-weight: 900;
        font-size: responsiveSize(22);
        margin-bottom: responsiveSize(6);

        span {
          font-weight: 400;
        }
      }

      input {
        margin-bottom: responsiveSize(13.5);
        height: responsiveSize(66);
        border: responsiveSize(4) solid #51AABC;
        border-radius: responsiveSize(11);
        padding-left: responsiveSize(31);
        font-size: responsiveSize(22);
      }

      .donation-section1-btn-mention {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: responsiveSize(24);

        button {
          cursor: pointer;
          border-radius: responsiveSize(4);
          width: fit-content;
          display: flex;
          height: responsiveSize(51);
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #51AABC;
          font-family: 'Avenir';
          font-weight: 800;
          font-size: responsiveSize(29);
          border: none;
          padding: responsiveSize(6) responsiveSize(78);

          &[disabled] {
            cursor: default;
            opacity: 0.4;
          }
        }

        p {
          color: #4CB3C4;
          font-size: responsiveSize(22);
          font-weight: 300;
          text-align: center;
          margin-top: responsiveSize(37);
        }
      }
    }
  }

  @media only screen and (max-width: 775px) {
    height: fit-content;
    padding-bottom: responsiveSizeMobile(100);
    background: linear-gradient(to bottom, #4CB3C4 responsiveSizeMobile(204), #F4DBFD responsiveSizeMobile(1800), white);

    .donation-section1-content {
      flex-direction: column;
      width: 100%;
      padding-top: responsiveSizeMobile(33);

      .donation-section1-col1 {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        h1 {
          margin-bottom: 0;
          font-size: responsiveSizeMobile(61);
          line-height: responsiveSizeMobile(67);
          margin-top: responsiveSizeMobile(33);
        }

        .lf-player-container {
          margin-top: responsiveSizeMobile(-33);
          width: responsiveSizeMobile(841);
          height: responsiveSizeMobile(841);
        }
      }

      .donation-section1-col2 {
        margin: auto;
        width: responsiveSizeMobile(708);
        height: responsiveSizeMobile(912);
        border-radius: responsiveSizeMobile(25);
        padding: responsiveSizeMobile(35);

        h2 {
          font-size: responsiveSizeMobile(43);
          margin-bottom: responsiveSizeMobile(14);
        }

        p {
          font-weight: 700;
          text-align: center;
          font-size: responsiveSizeMobile(24);
          margin-bottom: responsiveSizeMobile(45);
          line-height: responsiveSizeMobile(30);
        }

        input {
          margin-bottom: responsiveSizeMobile(17);
          height: responsiveSizeMobile(73);
          border: responsiveSizeMobile(4) solid #51AABC;
          border-radius: responsiveSizeMobile(11);
          padding-left: responsiveSizeMobile(31);
          font-size: responsiveSizeMobile(23);
        }

        label {
          color: black;
          font-size: responsiveSizeMobile(23);
          margin-bottom: responsiveSizeMobile(6);
        }

        .donation-section1-btn-mention {
          margin-top: responsiveSizeMobile(37);

          button {
            text-transform: uppercase;
            border-radius: responsiveSizeMobile(4);
            height: responsiveSizeMobile(85);
            width: responsiveSizeMobile(516);
            font-size: responsiveSizeMobile(45);
          }

          p {
            margin-bottom: 0;
            font-size: responsiveSizeMobile(22);
            margin-top: responsiveSizeMobile(11);
          }
        }
      }
    }
  }
}