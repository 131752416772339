@import "../../common.scss";

.partner-section1 {
  height: responsiveSize(1034);
  background-color: #BCA571;

  .partner-section1-content {
    img {
      bottom: 0;
      left: responsiveSize(-44);
      width: responsiveSize(755);
      height: responsiveSize(599);
      position: absolute;
      z-index: 10;
    }

    height: 100%;
    width: responsiveSize(1320);
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;

    h1 {
      width: responsiveSize(558);
      color: black;
      margin-top: responsiveSize(135);
      font-family: 'minion-pro';
      font-size: responsiveSize(50);
      line-height: responsiveSize(55);
      font-weight: bold;
      text-align: center;
    }

    .partner-section1-form {
      margin-top: responsiveSize(55);
      position: relative;
      z-index: 11;
      width: responsiveSize(708);
      height: responsiveSize(865);
      border-radius: responsiveSize(25);
      margin-left: responsiveSize(30);
      color: black;
      background-color: white;
      font-family: 'Avenir';
      display: flex;
      flex-direction: column;
      padding: responsiveSize(38);
      box-sizing: border-box;

      label {
        color: #51AABC;
        font-weight: 900;
        font-size: responsiveSize(22);
        margin-bottom: responsiveSize(6);

        span {
          font-weight: 400;
        }
      }

      textarea,
      input {
        font-family: 'Avenir';
        margin-bottom: responsiveSize(13.5);
        height: responsiveSize(66);
        border: responsiveSize(4) solid #51AABC;
        border-radius: responsiveSize(11);
        padding-left: responsiveSize(31);
        font-size: responsiveSize(22);
      }

      textarea {
        height: responsiveSize(320);
        padding-top: responsiveSize(19);
      }

      .messageLottie {
        position: absolute;
        z-index: 12;
        bottom: responsiveSize(260);
        left: responsiveSize(228);
        width: responsiveSize(253);
        height: responsiveSize(253);

        .hide {
          position: absolute;
          opacity: 0;
        }

        .display {
          opacity: 1;
          transition: 1s opacity ease-in;
        }
      }

      .partner-section1-btn-mention {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: responsiveSize(24);

        button {
          cursor: pointer;
          border-radius: responsiveSize(4);
          width: fit-content;
          display: flex;
          height: responsiveSize(51);
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #51AABC;
          font-family: 'Avenir';
          font-weight: 800;
          font-size: responsiveSize(29);
          border: none;
          padding: responsiveSize(6) responsiveSize(78);

          &[disabled] {
            cursor: default;
            opacity: 0.4;
          }
        }

        p {
          color: #4CB3C4;
          font-size: responsiveSize(22);
          font-weight: 300;
          text-align: center;
          margin-top: responsiveSize(37);
        }
      }
    }
  }

  @media only screen and (max-width: 775px) {
    height: fit-content;

    .partner-section1-content {
      width: 100%;
      align-items: center;
      flex-direction: column;

      h1 {
        width: responsiveSizeMobile(711);
        font-size: responsiveSizeMobile(55);
        margin-top: responsiveSizeMobile(33);
        line-height: responsiveSizeMobile(55);
      }

      .partner-section1-form {
        margin: auto;
        width: responsiveSizeMobile(708);
        height: responsiveSizeMobile(912);
        border-radius: responsiveSizeMobile(25);
        padding: responsiveSizeMobile(35);
        margin-bottom: responsiveSizeMobile(39);

        label {
          color: black;
          font-size: responsiveSizeMobile(23);
          margin-bottom: responsiveSizeMobile(6);
        }

        input,
        textarea {
          margin-bottom: responsiveSizeMobile(17);
          height: responsiveSizeMobile(73);
          border: responsiveSizeMobile(4) solid #51AABC;
          border-radius: responsiveSizeMobile(11);
          padding-left: responsiveSizeMobile(31);
          font-size: responsiveSizeMobile(23);
        }

        textarea {
          height: responsiveSizeMobile(320);
          padding-top: responsiveSizeMobile(19);
        }

        .messageLottie {
          bottom: responsiveSizeMobile(260);
          left: responsiveSizeMobile(228);
          width: responsiveSizeMobile(253);
          height: responsiveSizeMobile(253);
        }

        .partner-section1-btn-mention {
          margin-top: responsiveSizeMobile(37);

          button {
            text-transform: uppercase;
            border-radius: responsiveSizeMobile(4);
            height: responsiveSizeMobile(85);
            width: responsiveSizeMobile(516);
            font-size: responsiveSizeMobile(45);
          }

          p {
            margin-bottom: 0;
            font-size: responsiveSizeMobile(22);
            margin-top: responsiveSizeMobile(11);
          }
        }
      }
    }
  }
}