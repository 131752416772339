@import "../../common.scss";

.donnation-section2 {
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: responsiveSize(58);
  padding-bottom: responsiveSize(51);

  .donnation-section2-frame {
    width: responsiveSize(1246);
    display: flex;
    justify-content: space-between;
    text-align: right;

    .donnation-section2-col1 {
      width: responsiveSize(427);
      margin-right: responsiveSize(32);

      h2 {
        margin-top: responsiveSize(28);
        font-family: 'minion-pro';
        font-size: responsiveSize(40);
        font-weight: bold;
        line-height: responsiveSize(40);
      }

      p {
        font-family: 'Avenir';
        font-size: responsiveSize(22);
        font-weight: 300;
        line-height: responsiveSize(30);
      }

      a {
        padding: responsiveSize(6) responsiveSize(41);
        height: responsiveSize(55);
        font-family: 'Avenir';
        font-weight: 700;
        font-size: responsiveSize(29);
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: responsiveSize(4);
        color: white;
        background-color: #51AABC;
      }
    }

    .donnation-section2-col2 {
      width: responsiveSize(768);
      height: responsiveSize(545);
      position: relative;

      img {
        width: responsiveSize(768);
        height: responsiveSize(545);
      }

      a {
        width: responsiveSize(142);
        height: responsiveSize(142);
        bottom: 0;
        right: responsiveSize(-10);
        position: absolute;
      }
    }
  }

  @media only screen and (max-width: 775px) {
    padding-top: 0;
    padding-bottom: responsiveSizeMobile(86);

    .donnation-section2-frame {
      margin: auto;
      flex-direction: column;
      width: responsiveSizeMobile(708);

      .donnation-section2-col1 {
        width: 100%;
        margin: auto;

        h2 {
          margin: auto;
          margin-top: 0;
          text-align: center;
          width: responsiveSizeMobile(630);
          font-size: responsiveSizeMobile(60);
          line-height: responsiveSizeMobile(63);
        }
      }

      .donnation-section2-col2 {
        margin-top: responsiveSizeMobile(30);

        &,
        img {
          width: responsiveSizeMobile(708);
          height: responsiveSizeMobile(502);
        }

        a {
          width: responsiveSizeMobile(101);
          height: responsiveSizeMobile(101);
          right: responsiveSizeMobile(-10);
        }
      }

      p.mobile {
        margin: auto;
        text-align: center;
        font-family: 'Avenir';
        width: responsiveSizeMobile(662);
        font-size: responsiveSizeMobile(24);
        margin-top: responsiveSizeMobile(17);
        line-height: responsiveSizeMobile(30);
      }

      a.mobile {
        color: black;
        margin: auto;
        margin-top: responsiveSizeMobile(38);
        width: responsiveSizeMobile(516);
        height: responsiveSizeMobile(85);
        font-family: 'Avenir';
        font-weight: 700;
        font-size: responsiveSizeMobile(45);
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: responsiveSizeMobile(14);
        border: responsiveSizeMobile(4) solid black;
        background-color: white;
      }
    }
  }
}