@import "../../common.scss";

h2 {
  font-weight: inherit;
  margin: 0;
}

.video-presentation {
  background: #eac676;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10;
    width: responsiveSize(1320);
    margin: 0 auto;
    padding-top: responsiveSize(47);
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: responsiveSize(35);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
    color: black;
    margin-bottom: responsiveSize(55);

    &--anim {
      width: responsiveSize(118);
      height: responsiveSize(118);
    }
  }

  &__vimeo {
    margin-top: inherit;
    width: 100%;
    height: responsiveSize(743);

    iframe {
      border: 0;
      border-radius: responsiveSize(43);
    }
  }

  &__awlad-boat {
    flex: 1;
    display: flex;
    position: relative;
    align-items: flex-end;
    overflow: hidden;
    height: responsiveSize(400);
    margin-top: responsiveSize(15);

    &--boat {
      z-index: 2;
      position: absolute;
      bottom: responsiveSize(-88);
      right: responsiveSize(269);
      width: responsiveSize(751);
    }

    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }

    &--waves {
      z-index: 3;
      position: relative;
      margin-bottom: responsiveSize(-33) !important;
      width: 100%;
    }
  }

  @media only screen and (max-width: 775px) {
    &__title {
      display: none;
    }

    &__vimeo {
      margin-top: responsiveSizeMobile(88);
      width: responsiveSizeMobile(728);
      height: responsiveSizeMobile(410);

      iframe {
        border-radius: responsiveSizeMobile(24);
      }
    }

    &__awlad-boat {
      width: 100%;
      margin-top: responsiveSizeMobile(11);
      height: responsiveSizeMobile(250);
      margin-top: responsiveSizeMobile(15);

      &--boat {
        bottom: responsiveSizeMobile(-60);
        left: responsiveSizeMobile(-38);
        width: responsiveSizeMobile(609);
        height: responsiveSizeMobile(406);
      }

      &--waves {
        position: absolute;
        margin-bottom: responsiveSizeMobile(443);
        width: responsiveSizeMobile(1270);
        height: responsiveSizeMobile(221);
      }

      &--mobile {
        display: block;
      }

      &--desktop {
        display: none;
      }
    }
  }
}
