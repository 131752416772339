@import "../../common.scss";

.testimonial {
  background-color: #51aabc;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: responsiveSize(1320);
    margin: 0 auto responsiveSize(50) auto;
  }

  &__title {
    text-align: center;
    font-size: responsiveSize(65);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
    margin-top: responsiveSize(37);
    margin-bottom: responsiveSize(45);
    height: fit-content;
    line-height: responsiveSize(65);
    margin: 0;
  }

  &__ratings-container {
    display: flex;
    align-items: center;
  }

  &__ratings {
    display: flex;
    align-items: center;
    width: responsiveSize(530);

    img {
      height: responsiveSize(99);
      width: responsiveSize(99);
    }
  }

  &__favourite {
    height: responsiveSize(131);
    width: responsiveSize(131);
    transform: translateX(responsiveSize(-8));
  }

  &__rating-text {
    display: flex;
    align-items: center;
    font-family: "Fredoka SemiCondensed";
    font-size: responsiveSize(36);
    font-weight: 500;
    gap: responsiveSize(15);
    color: #052a75;

    strong {
      font-size: responsiveSize(90);
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: responsiveSize(42);
    }
  }

  &__scrollable-frame {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__list {
    display: flex;
    width: auto;
    animation: scroll linear infinite;

    @keyframes scroll {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }

  .testimony {
    margin-left: responsiveSize(33);
    padding: 0 responsiveSize(20);

    &:last-child {
      margin-right: responsiveSize(33);
    }
  }
  @media only screen and (max-width: 775px) {
    height: fit-content;
    padding-bottom: responsiveSizeMobile(40);

    &__title {
      font-size: responsiveSizeMobile(50);
      margin-top: responsiveSizeMobile(10);
      margin-bottom: responsiveSizeMobile(10);
      line-height: responsiveSizeMobile(50);
      width: responsiveSizeMobile(720);
    }

    &__ratings-container {
      flex-direction: column;
    }

    &__ratings {
      width: responsiveSizeMobile(513);

      img {
        height: responsiveSizeMobile(91);
        width: responsiveSizeMobile(91);
      }
    }

    &__favourite {
      height: responsiveSizeMobile(127);
      width: responsiveSizeMobile(126);
      transform: translateX(responsiveSizeMobile(-8));
    }

    &__rating-text {
      gap: responsiveSizeMobile(15);
      font-size: responsiveSizeMobile(23);

      strong {
        font-size: responsiveSizeMobile(59);
      }

      p {
        margin: 0;
        font-size: responsiveSizeMobile(28);
      }
    }

    .stores-container {
      display: none;
    }

    &__container {
      width: inherit;
    }

    .testimony {
      margin-left: responsiveSizeMobile(33);
      padding: 0 responsiveSizeMobile(20);

      &:last-child {
        margin-right: responsiveSizeMobile(33);
      }
    }
  }
}
