@import "../../common.scss";

.features {
  position: relative;
  background: linear-gradient(to bottom, #51aabc, #274d79, #b284c3);
  padding-bottom: responsiveSize(180);
  padding-top: responsiveSize(60);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: responsiveSize(1660);
    margin: auto;
    gap: responsiveSize(100);
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: responsiveSize(65);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
  }

  &__item {
    font-size: responsiveSize(51);
    font-family: "Chaloops";
    font-weight: 400;
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    position: relative;

    &--item1 {
      background-image: url("../../../public/images/features-1-desktop.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: responsiveSize(964);
      height: responsiveSize(787);
    }

    &--sub1 {
      position: absolute;
      width: responsiveSize(433);
      height: responsiveSize(289);
      left: responsiveSize(-80);
      top: responsiveSize(600);
    }
    &--sub2 {
      position: absolute;
      width: responsiveSize(476);
      height: responsiveSize(317);
      right: responsiveSize(-80);
      top: responsiveSize(-230);
    }

    &--text1 {
      margin-top: responsiveSize(150);
      rotate: 2deg;
      margin-right: responsiveSize(30);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: responsiveSize(40);
        top: responsiveSize(200);
        background-image: url("../../../public/images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: responsiveSize(71);
        width: responsiveSize(84);
        transform: scaleX(-1);
        rotate: -7deg;
      }
    }

    &--text1-desktop {
      margin-top: responsiveSize(375);
      rotate: -3deg;
      margin-left: responsiveSize(30);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: responsiveSize(70);
        top: responsiveSize(-70);
        background-image: url("../../../public/images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: responsiveSize(71);
        width: responsiveSize(84);
        transform: scaleX(-1);
        rotate: -185deg;
      }
    }

    &--text2 {
      margin-top: responsiveSize(130);
      rotate: -4deg;
      position: relative;
      margin-right: responsiveSize(30);
      &:after {
        content: "";
        position: absolute;
        right: responsiveSize(30);
        top: responsiveSize(200);
        background-image: url("../../../public/images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: responsiveSize(71);
        width: responsiveSize(84);
        transform: scaleX(-1);
        rotate: -20deg;
      }
    }

    &--text2-desktop {
      margin-top: responsiveSize(230);
      rotate: 4deg;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: responsiveSize(70);
        top: responsiveSize(230);
        background-image: url("../../../public/images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: responsiveSize(71);
        width: responsiveSize(84);
        rotate: -10deg;
      }
    }

    &--text3 {
      margin-top: responsiveSize(300);
      rotate: 2deg;
      margin-right: responsiveSize(30);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: responsiveSize(50);
        top: responsiveSize(-60);
        background-image: url("../../../public/images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: responsiveSize(71);
        width: responsiveSize(84);
        rotate: 200deg;
      }
    }

    &--text3-desktop {
      margin-top: responsiveSize(360);
      rotate: -1deg;
      margin-left: responsiveSize(30);
      position: relative;
    }
  }

  &__illustration {
    &--2 {
      transform: translateX(responsiveSize(-40));
    }
  }

  &__illustration,
  &__illustration-3 {
    display: flex;
    overflow: inherit;
    width: responsiveSize(964);
    height: responsiveSize(787);
  }

  &__illustration-city {
    position: absolute;
    bottom: responsiveSize(-25);
    left: -4%;
    background-image: url("../../../public/images/city-desktop.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: responsiveSize(217);
    width: responsiveSize(2773);
  }

  @media only screen and (max-width: 775px) {
    padding: 0 responsiveSizeMobile(46) responsiveSizeMobile(270)
      responsiveSizeMobile(46);

    &__container {
      width: 100%;
    }

    &__title {
      flex-direction: column;
      font-size: responsiveSizeMobile(45);

      &--anim {
        width: responsiveSizeMobile(202);
        height: responsiveSizeMobile(202);
      }
    }

    &__item {
      width: 100%;
      font-size: responsiveSizeMobile(46);
      display: flex;
      flex-direction: row;
      text-align: center;
      position: relative;

      &--text1,
      &--text2 {
        position: relative;
        margin-top: responsiveSizeMobile(100);
      }

      &--text1 {
        rotate: 2deg;

        &:after {
          content: "";
          position: absolute;
          right: responsiveSizeMobile(150);
          top: responsiveSizeMobile(200);
          background-image: url("../../../public/images/arrow.svg");
          background-size: contain;
          background-repeat: no-repeat;
          height: responsiveSizeMobile(79);
          width: responsiveSizeMobile(69);
          rotate: inherit;
          transform: inherit;
        }
      }
      &--text2 {
        rotate: -5deg;
        white-space: nowrap;

        &:after {
          content: "";
          position: absolute;
          right: responsiveSizeMobile(70);
          left: inherit;
          top: responsiveSizeMobile(170);
          background-image: url("../../../public/images/arrow.svg");
          background-size: contain;
          background-repeat: no-repeat;
          height: responsiveSizeMobile(79);
          width: responsiveSizeMobile(69);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          rotate: -25deg;
        }
      }
      &--text3 {
        position: absolute;
        top: responsiveSizeMobile(-330);
        left: responsiveSizeMobile(-20);
        margin: 0;
        rotate: inherit;

        &:after {
          content: "";
          position: absolute;
          right: responsiveSizeMobile(90);
          top: responsiveSizeMobile(200);
          background-image: url("../../../public/images/arrow.svg");
          background-size: contain;
          background-repeat: no-repeat;
          height: responsiveSizeMobile(90);
          width: responsiveSizeMobile(70);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          rotate: 20deg;
        }
      }

      &--sub1 {
        bottom: 0;
        left: inherit;
        top: inherit;
        right: responsiveSizeMobile(-70);
        width: responsiveSizeMobile(433);
        height: responsiveSizeMobile(289);
      }
    }

    &__illustration {
      display: flex;
      overflow: hidden;
      width: responsiveSizeMobile(376);
      height: responsiveSizeMobile(804);

      &--2 {
        margin-left: auto;
        transform: inherit;
      }
    }

    &__illustration-3 {
      width: responsiveSizeMobile(964);
      height: responsiveSizeMobile(787);
      transform: translateX(responsiveSizeMobile(-165));

      img {
        width: auto;
        height: 100%;
      }
    }

    &__free {
      position: relative;
      color: #4cb3c4;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      position: relative;
      background-color: white;
      font-family: "Fredoka SemiCondensed";
      font-weight: 600;
      border-radius: responsiveSizeMobile(8);
      width: responsiveSizeMobile(678);
      height: responsiveSizeMobile(68);
      font-size: responsiveSizeMobile(40);

      &--heart {
        top: responsiveSizeMobile(-14);
        right: inherit;
        width: responsiveSizeMobile(89);
        height: responsiveSizeMobile(89);
      }
    }

    &__illustration-city {
      bottom: responsiveSizeMobile(-25);
      left: -45%;
      background-image: url("../../../public/images/city.svg");
      background-repeat: no-repeat;
      background-size: cover;
      height: responsiveSizeMobile(217);
      width: responsiveSizeMobile(1457);
    }
  }
}
