@import "../common.scss";

footer {
  width: responsiveSize(var(--desktopWidthRef));
  height: responsiveSize(879);
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .logo-and-partner {
    flex: 1;
    width: responsiveSize(1320);
    display: flex;
  }

  .col1 {
    width: responsiveSize(665);
    margin-top: responsiveSize(78);

    svg {
      width: responsiveSize(391);
      height: responsiveSize(176);

      path {
        fill: white;
      }
    }

    p {
      line-height: responsiveSize(40);
      font-size: responsiveSize(30);
      font-family: "Baloo Bhaijaan 2";
    }
  }

  .col2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      line-height: responsiveSize(40);
      color: #808080;
      font-weight: 400;
      font-size: responsiveSize(35);
      font-family: "Baloo Bhaijaan 2";
    }

    .stores-container {
      margin-top: responsiveSize(54);

      &__link {
        &--apple {
          border: responsiveSize(3) solid #a2a2a1;
        }
      }
    }

    .partner-logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        svg {
          margin-top: responsiveSize(44);
        }

        &.alterwaylogo svg {
          height: responsiveSize(122);
          width: responsiveSize(391);
        }
      }

      .bdouinlogo {
        height: responsiveSize(180);
        width: responsiveSize(200);
      }
    }
  }

  .copyright {
    width: 100%;
    height: responsiveSize(76);
    border-top: 2px solid #ededed;
    font-family: "Avenir";
    font-weight: 900;
    font-size: responsiveSize(27);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 775px) {
    height: fit-content;

    .logo-and-partner {
      width: 100%;
      flex-direction: column;

      .col1 {
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: responsiveSizeMobile(665);
        margin-top: responsiveSizeMobile(84);

        svg {
          width: responsiveSizeMobile(391);
          height: responsiveSizeMobile(176);
        }

        p {
          text-align: center;
          font-size: responsiveSizeMobile(35);
          line-height: responsiveSizeMobile(40);
          margin-top: responsiveSizeMobile(35);
        }
      }

      .col2 {
        h1 {
          margin-top: 0;
          font-size: responsiveSizeMobile(35);
          line-height: responsiveSizeMobile(40);
        }

        .stores-container {
          display: none;
        }

        .partner-logo {
          flex-direction: row;
          margin-bottom: responsiveSizeMobile(123);

          a {
            svg {
              margin-top: 0;
            }

            &.alterwaylogo svg {
              height: responsiveSizeMobile(122);
              width: responsiveSizeMobile(391);
            }
          }

          .bdouinlogo {
            height: responsiveSizeMobile(180);
            width: responsiveSizeMobile(200);
            margin-left: responsiveSizeMobile(74);
          }
        }
      }
    }

    .copyright {
      height: responsiveSizeMobile(76);
      font-size: responsiveSizeMobile(27);
    }
  }
}
